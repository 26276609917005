import React from 'react';
import { useFrontPageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import ArticleContent from '@c/ArticleContent';
import Layout from '@c/Layout';
import Hero from '@c/Hero';

const IndexPage = () => {
  const frontPageCmsEntry = useFrontPageEntry(),
    frontPageEntry = frontPageCmsEntry.entry,
    seomatic = frontPageCmsEntry.seomatic;

  return (
    <EntryContextProvider entry={frontPageEntry}>
      <Layout seomatic={seomatic}>
        {!!frontPageEntry.hero && !!frontPageEntry.hero.length && (
          <Hero {...frontPageEntry.hero[0]} />
        )}

        <ArticleContent content={frontPageEntry.articleContent} />
      </Layout>
    </EntryContextProvider>
  );
};

export default IndexPage;
